<template lang="pug">
  .services.max-width-s
    section
      h1 We’re a small agency with few clients. And we like it that way.
      p.no-margin
        | We know that a business, like any other worldly entitly, needs tending in order to flourish.
        | To ensure we can meet those needs continually and effectively,
        | <strong>we deliberately keep our roster small.</strong>
    section.row.row-bp-xs-up-half.align-center.gap
      div
        h2 Our Clients
        p
          | <i>We do not release information about our current client list on our site.</i>
          | We like to keep our cards close to our chest.
        p.no-margin
          | Not to worry, once we get to know each other, we'll open the kimono.
          | <strong>Like all good things, we're worth the wait!</strong>
      div
        vue-image(:width="696" :height="522" :source="require('@/assets/clients.jpg')" alt="Our Business")
    section.row.row-reverse.row-bp-xs-up-half.align-center.gap
      div
        h2 Our Business
        p
          | Anchor is <strong>Veteran Owned and Operated</strong>.
        p
          | Although our active duty days are over, we bring the work ethic
          | and uncompromising dedication we gained from our time served.
      div
        vue-image(:width="500" :height="500" :source="require('@/assets/vob-badge.jpg')" alt="Veteran Owned Business")
    contact-block
</template>

<script>
export default {
  name: 'About'
}
</script>
