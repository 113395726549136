<template lang="pug">
  .services.max-width-s
    section.header-section
      h1 We live on the web —<br> now more than ever.
      p
        | In the age of smartphones, Amazon Prime, and Facebook, the internet has become our lifeline to the world around us.&nbsp;
        | For businesses, this can be overwhelming; almost like
        | <strong>being stranded in an ocean.</strong>
      p.no-margin
        | Whether it’s creating a web presence, increasing online sales,
        | or optimizing your customers experience,
        | <strong>we'll be your anchor in the digital sea.</strong>

    section
      .row.row-bp-xs-up-half.align-center.gap
        div
          h2 Our Services
          p.no-margin
            | We’ve been working on the web for over a decade,
            | and have the expertise needed to give your business a virtual paddle.
        div
          vue-image(:width="696" :height="522" :source="require('@/assets/paddle.jpg')" alt="Our Services")

    section
      h2 Web Development
      p.no-margin
        | A website is the launchpad for exposing your business to the digital world.
        | We can help it grow with whatever level of development needed to make that happen.

    section.row.row-reverse.row-bp-xs-up-half.align-center.gap
      div
        h3 Development Services:
        ul.no-margin
          li New Site Creation
          li Existing Site Maintenance
          li Custom Feature Development
      div
        vue-image(:width="696" :height="462" :source="require('@/assets/code.jpg')" alt="Web Development")

    section
      div
        h2 B2C Ecommerce
        p.no-margin
          | Customers have come to expect your business to sell online.
          | We know the ins and outs of the platforms that make it possible, and can guide you through the process of picking one,
          | setting it up, and maintaining it.

    section.row.row-bp-xs-up-half.align-center.gap
      div
        h3 Ecommerce Platforms:
        ul.no-margin
          li Shopify
          li Magento
          li WooCommerce
      div
        vue-image(:width="696" :height="462" :source="require('@/assets/ecommerce.jpg')" alt="Ecommerce Platforms")

    section
      h2 SEO & Analytics
      p.no-margin
        | Search engines are integral to helping customers find you online, and analytics give insight into the what,
        | when, and why behind your customer’s path there. By optimizing your site, and using data to make strategic decisions,
        | we ensure your site works for you, and not the other way around.

    section.row.row-reverse.row-bp-xs-up-half.align-center.gap
      div
        h3 Optimization Services:
        ul.no-margin
          li Search Engine Optimization
          li A/B Testing
          li Google Analytics
      div
        vue-image(:width="696" :height="424" :source="require('@/assets/seo.jpg')" alt="Search Engine Optimization")

    contact-block
</template>

<script>
export default {
  name: 'Services'
}
</script>

<style lang="scss" scoped>
.header-section {
  br {
    display: none;

    @media(min-width: $bp-s) {
      display: inline-block;
    }
  }
}
</style>
