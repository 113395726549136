<template lang="pug">
  .contact.max-width-s
    section
      h1 Contact Us
      p.no-margin
        | <strong>It looks like you're ready to drop anchor!</strong>
        | Get in touch with us in whatever way works best for you!
    section.row.row-half.gap
      div
        h2 Email
        a(href="mailto:info@anchoragency.co" target="_blank") Email Us Directly
      div
        h2 Call
        a(href="https://calendly.com/mike-1419" target="_blank") Schedule A Call
    section
      div
        h2 Message
        form(id="ct-form" action="//formspree.io/info@anchoragency.co" method="POST" @submit="setLoading()")
          .row.row-half.gap
            .required
              label Name
              input(id="ct-name" type="text" name="name" placeholder="Name" required="")
            .required
              label Email
              input(id="ct-email" type="email" name="_replyto" placeholder="Email" required="")
          .required
            label Subject
            input(id="ct-subject" name="_subject" type="text" placeholder="Subject" required="")
          .required
            label Message
            textarea(id="ct-message" rows="3" placeholder="Something like: Hey Anchor! We'd love to start a relationship with you!" name="message" required="")
          input(type="text" name="_gotcha" style="display: none")
          button(class="button" type="submit"  :class="{ 'loading': loading }") Contact Us
</template>

<script>
export default {
  name: 'Contact',

  data () {
    return {
      loading: false
    }
  },

  methods: {
    setLoading () {
      this.loading = true
    }
  }
}
</script>

<style lang="scss" scoped>
.contact {
  input, textarea, button {
    width: 100%;
    display: block;
    margin-bottom: 1.5em;
  }
}
</style>
