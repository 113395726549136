<template>
  <div class="about max-width-s">
    <section>
      <h1>Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <h5>Heading 5</h5>
      <h6>Heading 6</h6>
    </section>

    <section>
    <p>
      Paragraph text dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
    <p>
      Paragraph text dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
    </section>

    <section class="row row-bp-xs-up-half">
      <ul>
        <li>Bulleted List Item 1</li>
        <li>Bulleted List Item 2</li>
        <li>Bulleted List Item 3</li>
        <li>Bulleted List Item 4</li>
      </ul>
      <ol>
        <li>Numbered List Item 1</li>
        <li>Numbered List Item 2</li>
        <li>Numbered List Item 3</li>
        <li>Numbered List Item 4</li>
      </ol>
    </section>

    <section class="row row-bp-xs-up-half">
      <div>
        <a href="#">Link</a>
        <br>
        <a href="#" class="hover">Link - Hover</a>
        <br>
        <a href="#" class="active">Link - Pressed</a>
        <br><br>
      </div>
      <div>
        <a href="#" class="featured">Featured Link</a>
        <br>
        <a href="#" class="featured hover">Featured Link - Hover</a>
        <br>
        <a href="#" class="featured active">Featured Link - Pressed</a>
        <br>
      </div>
    </section>

    <section class="row row-bp-xs-up-half">
      <div>
        <a href="#" class="button">Button</a>
        <br><br>
        <a href="#" class="hover button">Button - Hover</a>
        <br><br>
        <a href="#" class="active button">Button - Pressed</a>
        <br><br>
      </div>
      <div>
        <a href="#" class="button secondary">Secondary Button</a>
        <br><br>
        <a href="#" class="hover button secondary">Secondary Button - Hover</a>
        <br><br>
        <a href="#" class="active button secondary">Secondary Button - Pressed</a>
        <br><br>
      </div>
    </section>

    <section class="row row-bp-xs-up-half">
      <div>
        <div class="checkbox">
          <input type="checkbox" id="checkbox-1">
          <label for="checkbox-1">Checkbox</label>
        </div>
        <div class="checkbox">
          <input type="checkbox" id="checkbox-2" class="hover">
          <label for="checkbox-2">Checkbox Hover</label>
        </div>
        <div class="checkbox">
          <input checked="checked" type="checkbox" id="checkbox-3">
          <label for="checkbox-3">Checkbox Checked</label>
        </div>
        <div class="checkbox">
          <input disabled="disabled" type="checkbox" id="checkbox-4">
          <label for="checkbox-4">Checkbox Disabled</label>
        </div>
        <br>
      </div>
      <div>
        <div class="radio">
          <input type="radio" name="radio" id="radio-1">
          <label for="radio-1">Radio</label>
        </div>
        <div class="radio">
          <input name="radio" type="radio" id="radio-2" class="hover">
          <label for="radio-2">Radio Hover</label>
        </div>
        <div class="radio">
          <input checked="checked" type="radio" name="radio" id="radio-3">
          <label for="radio-3">Radio Selected</label>
        </div>
        <div class="radio">
          <input disabled="disabled" type="radio" name="radio" id="radio-4">
          <label for="radio-4">Radio Disabled</label>
        </div>
      </div>
    </section>

    <section class="row gap row-bp-xs-up-half">
      <div>
        <label for="input-1">Input Label</label>
        <input id="input-1" placeholder="Input Placeholder" />
      </div>
      <div>
        <label for="input-2">Input Active/hover</label>
        <input id="input-2" placeholder="Input Placeholder" class="hover" />
      </div>
      <div>
        <label for="input-3">Input Disabled</label>
        <input id="input-3" placeholder="Input Placeholder" disabled />
      </div>
      <div class="required">
        <label for="input-4">Input Required</label>
        <input id="input-4" placeholder="Input Placeholder" />
      </div>
    </section>

  </div>
</template>

<script>
export default {
  name: 'StyleGuide'
}
</script>
